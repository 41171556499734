import React, { useEffect }  from "react";
import { Container } from "react-bootstrap";
import { Link, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import SignUpForm from "../components/SignUpForm";

const SignUp = () => {
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const rcValue = queryParams.get('rc');

    if (rcValue) {
      Cookies.set('zrl_referrer_code', rcValue, { expires: 10 / 1440 });
    }
  }, [location.search]);

  return (
    <div className="Formbg">
      <div className="signUpTopBox">
        <div className="signUpTopBoxTitle"><h4>Rewards Program For Automotive Technicians</h4></div>
      </div>
      <Container>
        <div className="formBox">
          <SignUpForm />
          <p className="haveAccount">
            Have an account? <Link to="/login">Sign in</Link>
          </p>
        </div>
      </Container>
    </div>
  );
};

export default SignUp;
