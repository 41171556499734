import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Auth } from "aws-amplify";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import Loading from "./Loading";
import "react-datepicker/dist/react-datepicker.css";

const SignupSchema = yup.object().shape({
  firstName: yup.string().required("First Name is a required field"),
  lastName: yup.string().required("Last Name is a required field"),
  email: yup
    .string()
    .email("This is not a valid email")
    .required("Email is a required field"),
  password: yup
    .string()
    .required("Please enter your password")
    .min(8, "Your password must be longer than 8 characters.")
    .matches(/^(?=.{8,})/, "A minimum of 8 characters")
    .matches(/^(?=.*[0-9])/, "At least 1 number")
    .matches(/^(?=.*[A-Z])/, "At least 1 upper case character")
    .matches(/^(?=.*[a-z])/, "At least 1 lower case character")
    .matches(/^(?=.*[!@#\$%\^&\*])/, "At least 1 special character"),
  passwordConfirmation: yup
    .string()
    .oneOf(
      [yup.ref("password"), null],
      "Password and confirmation password do not match"
    ),
  phoneNumber: yup
    .string()
    .nullable()
    .transform((_, originalValue) =>
      String(originalValue).trim() === "" ? "000-000-0000" : originalValue
    )
    .matches(
      /^\d{3}-\d{3}-\d{4}$/,
      "Phone number must be in the format XXX-XXX-XXXX"
    ),
  termsCondition: yup.bool().oneOf([true], "Checkbox selection is required"),
});

function SignUpForm() {
  const history = useNavigate();
  const [show, setShow] = useState(false);
  const [errorMessage, seterrorMessage] = useState();
  const [loader, setLoader] = useState(false);
  const [disable, setDisable] = useState(true);
  const [showVerifyErrorMessage, setshowVerifyErrorMessage] = useState(false);
  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(SignupSchema),
    mode: "onChange",
    criteriaMode: "all",
    reValidateMode: "onChange",
  });

  const onSubmit = async (data) => {
    const formatPhoneNumber = (value) => {
      return ("" + value).replace(/[^0-9]/g, "");
    };

    try {
      setLoader(true);
      const attributes = {
        name: data.firstName + " " + data.lastName,
        email: data.email,
        phone_number: `+1${formatPhoneNumber(data.phoneNumber)}`,
        family_name: data.lastName,
        given_name: data.firstName,
      };

      const cookies = document.cookie.split('; ');
      console.log(cookies);
      const referer_cookie = cookies.find(cookie => cookie.startsWith('zrl_referrer_code'));
      if (referer_cookie){
        const referrerCode = referer_cookie.split('=')[1];
        attributes['custom:referrer_code'] = referrerCode;
      }
      console.log(attributes)
      const result = await Auth.signUp({
        username: data.email,
        password: data.password,
        attributes: attributes
      })

      if (result) {
        seterrorMessage("");
        setLoader(false);
        history(`/confirm/`, {
          state: { email: data.email, fromPage: "signup" },
        });
      }
    } catch (error) {
      console.log("auth catch error...: ", error);
        setLoader(false);
        if (error.code && error.code === "UsernameExistsException") {
          console.log("error")
          setshowVerifyErrorMessage(false);
          seterrorMessage(
            <span style={{ fontWeight: "bold" }}>
              Email Address already exists. If you don’t remember your credentials, Please reset your password.
            </span>
          );
          setShow(true);
        } else if (
          error.code &&
          error.code === "UserLambdaValidationException" &&
          error.message ===
            "PreSignUp failed with error A user with the same email address exists."
        ) {
          // This error is handled because EJ Gallo has put lambda validation when user signs up
          setshowVerifyErrorMessage(false);
          seterrorMessage(
            <span style={{ fontWeight: "bold" }}>
              Email Address already exists. If you don’t remember your credentials, Please reset your password.
            </span>
          );
          setShow(true);
        } else {
          setshowVerifyErrorMessage(false);
          seterrorMessage(
            <span style={{ fontWeight: "bold" }}>
              There was an error while creating your Dorman rewards account. Please try again later.
            </span>
          );
          setShow(true);
        }
    }
    reset();
  };

  const handlePhoneNumberChange = (e) => {
    const inputValue = e.target.value;
    let formattedValue = "";
    const digits = inputValue.replace(/\D/g, "");

    for (let i = 0; i < digits.length; i++) {
      if (i === 3 || i === 6) {
        formattedValue += "-";
      }
      formattedValue += digits[i];
      if (i >= 9) break;
    }

    setValue("phoneNumber", formattedValue);
  };

  return (
    <div className="formWrap">
      {loader && <Loading />}
      <form onSubmit={handleSubmit((d) => console.log("DATAT: ", d))}>
        <h2>SIGN UP</h2>
        <div className={"formControl" + (errors.firstName ? " error" : "")}>
          <label>First Name*</label>
          <Form.Control autoComplete="nope" {...register("firstName")} />
          {errors.firstName && (
            <p className="errorMessage">{errors.firstName.message}</p>
          )}
        </div>
        <div className={"formControl" + (errors.lastName ? " error" : "")}>
          <label>Last Name*</label>
          <Form.Control autoComplete="nope" {...register("lastName")} />
          {errors.lastName && (
            <p className="errorMessage">{errors.lastName.message}</p>
          )}
        </div>
        <div className={"formControl" + (errors.email ? " error" : "")}>
          <label>Email Address*</label>
          <Form.Control autoComplete="nope" {...register("email")} />
          {errors.email && (
            <p className="errorMessage">{errors.email.message}</p>
          )}
        </div>
        <div className={"formControl" + (errors.password ? " error" : "")}>
          <label>Password*</label>
          <Form.Control
            autoComplete="nope"
            type="password"
            {...register("password")}
          />
          {errors?.password?.types?.matches && (
            <div>
              {errors?.password?.types?.matches && (
                <p className="errorMessage">Password must contain -</p>
              )}
              <ul>
                {typeof errors?.password?.types?.matches === "string" ? (
                  <li className="errorMessage">
                    {errors?.password?.types?.matches}
                  </li>
                ) : (
                  errors?.password?.types?.matches.length > 0 &&
                  errors?.password?.types?.matches?.map((err, index) => {
                    return (
                      <li key={index} className="errorMessage">
                        {err}
                      </li>
                    );
                  })
                )}
              </ul>
            </div>
          )}
        </div>
        <div
          className={
            "formControl" + (errors.passwordConfirmation ? " error" : "")
          }
        >
          <label>Confirm Password*</label>
          <Form.Control
            autoComplete="nope"
            type="password"
            {...register("passwordConfirmation")}
          />
          {errors.passwordConfirmation && (
            <p className="errorMessage">
              {errors.passwordConfirmation.message}
            </p>
          )}
        </div>
        <div className={"formControl" + (errors.phoneNumber ? " error" : "")}>
          <label>Phone Number</label>
          <Form.Control
            onWheel={(e) => e.target.blur()}
            autoComplete="nope"
            type="text"
            {...register("phoneNumber")}
            onChange={handlePhoneNumberChange}
          />
          {errors.phoneNumber && (
            <p className="errorMessage">{errors.phoneNumber.message}</p>
          )}
        </div>
        <p className="agreeterms">
          I agree to the below privacy statement and T&Cs*
        </p>
        <div className="termsBox">
          <Form.Check
            type="checkbox"
            {...register("termsCondition")}
            id="default-checkbox-terms"
            label={
              <span>
                Yes, I understand that the information I submit is being
                collected and used in accordance with Dorman's{" "}
                <a
                  href="https://www.dormanproducts.com/pages/web/privacy.aspx"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Statement{" "}
                </a>
                &{" "}
                <a
                  href="https://www.dormanproducts.com/pages/web/terms.aspx"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms And Conditions
                </a>
              </span>
            }
          />
          {errors.termsCondition && (
            <p className="errorMessage">{errors.termsCondition.message}</p>
          )}
        </div>
        {show && errorMessage && <p className="errorMessage">{errorMessage}</p>}
        <div>
          <Button
            className="signUpBtn themeBtn"
            type="submit"
            //disabled={!isValid || !isDirty || disable}
            onClick={handleSubmit(onSubmit)}
          >
            SIGN UP NOW
          </Button>
        </div>
      </form>
    </div>
  );
}

export default SignUpForm;
