import { useNavigate } from "react-router-dom";
import CreateAccount from "../Assets/Images/icon_create-account.png";
import NewProduct from "../Assets/Images/icon_new-product-idea.png";
import TechTraining from "../Assets/Images/icon_tech-training.png";
import OemDealerPurchase from "../Assets/Images/icon_oem-dealer-purchase.png";
import ProductFeedback from "../Assets/Images/icon_product-feedback.png";
import ProductPurchase from "../Assets/Images/icon_product-purchase.png";
import { Link } from "react-router-dom";

const EarnPoints = () => {
  const navigate = useNavigate();

  return (
    <div id="rewardsSection" className="earnPoints">
      <h2 className="earnPointsText">Easy ways to earn points.</h2>
      <div class="content">
        <ul>
          <div class="card" onClick={() => navigate("/login")}>
            <img src={NewProduct} alt="New Product Ideas" />
            <h3>New Product Ideas</h3>
            <p>Earn 100 points when you submit a new product idea.</p>
            <button class="button">100 POINTS</button>
          </div>
          <div class="card" onClick={() => navigate("/login")}>
            <img src={CreateAccount} alt="Create An Account" />
            <h3>Create An Account</h3>
            <p>Earn 100 points when you enroll in rewards programs.</p>
            <button class="button">100 POINTS</button>
          </div>
        </ul>
        <ul>
          <div class="card" onClick={() => navigate("/login")}>
            <img src={ProductFeedback} alt="Product Feedback" />
            <h3>Product Feedback</h3>
            <p>Earn 100 points when you provide us product feedback.</p>
            <button class="button">100 POINTS</button>
          </div>
          <div class="card" onClick={() => navigate("/login")}>
            <img src={TechTraining} alt="Technician Training" />
            <h3>Technician Training</h3>
            <p>
              Earn 100 points when you purchase or attend our technician
              training courses.
            </p>
            <button class="button">100 POINTS</button>
          </div>
        </ul>
        <ul>
          <div class="card" onClick={() => navigate("/login")}>
            <img src={OemDealerPurchase} alt="OEM Dealer Purchases" />
            <h3>OEM Dealer Purchases</h3>
            <p>Earn 100 points when you purchase parts for OEM dealer.</p>
            <button class="button">100 POINTS</button>
          </div>
          <div class="card" onClick={() => navigate("/login")}>
            <img src={ProductPurchase} alt="Product Purchase" />
            <h3>Product Purchase</h3>
            <p>Earn 100 points when you purchase a Dorman product.</p>
            <button class="button">100 POINTS</button>
          </div>
        </ul>
      </div>
      <p>
        <b>
          *Please Note! You can earn additional points for the photo upload
          activity by uploading pictures for:
        </b>
        <br />
        Posting events on social media | Event points on
        social media | Creative social media posts promoting Dorman branded
        products.
      </p>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button class="viewButton" onClick={() => navigate("/login")}>
          VIEW ALL
        </button>
      </div>
    </div>
  );
};

export default EarnPoints;
